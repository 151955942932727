import { IImageAccordionFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

import ImageAccordion, { ImageAccordionProps } from './ImageAccordion'

const ContentfulImageAccordion = withContentful<IImageAccordionFields, ImageAccordionProps>(ImageAccordion, {
  accordionItems: (props) => {
    return props.fields.accordionItems.map((item) => ({
      heading: item.fields?.heading,
      children: <BlockRenderer block={item.fields?.components} />,
      accordionItemImage: item.fields?.accordionItemImage,
      analytics: item.fields?.linkAnalyticsEvent,
      desktopImage: {
        src: item.fields?.accordionItemImage.fields.imageAsset.fields.file.url,
        height: item.fields?.accordionItemImage.fields.imageAsset.fields.file.details.image.height,
        width: item.fields?.accordionItemImage.fields.imageAsset.fields.file.details.image.width,
        alt: item.fields?.accordionItemImage.fields?.alt,
        rounded: item.fields?.accordionItemImage.fields?.rounded?.toLowerCase(),
      },
      desktopImageBlock: <BlockRenderer block={item.fields?.accordionItemImage} />,
      mobileImage: {
        src: item.fields?.mobileImage?.fields.imageAsset.fields.file.url,
        height: item.fields?.mobileImage?.fields.imageAsset.fields.file.details.image.height,
        width: item.fields?.mobileImage?.fields.imageAsset.fields.file.details.image.width,
        alt: item.fields?.mobileImage?.fields?.alt,
        rounded: item.fields?.mobileImage?.fields?.rounded?.toLowerCase(),
      },
      mobileImageBlock: <BlockRenderer block={item.fields?.mobileImage} />,
    }))
  },
  vertImageAlign: (props) =>
    props.fields.vertImageAlign?.toLowerCase() as Lowercase<typeof props.fields.vertImageAlign>,
})

export default ContentfulImageAccordion
